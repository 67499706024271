/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-02-01 18:02:36
 */
import request from '@/util/request'

// 查询订单消费明细
export function spendDetails(data) {
  return request({
    url: '/sale/api/order/query-spend-details',
    method: 'post',
    data
  })
}
// 查询订单明细
export function orderInfo(data) {
  return request({
    url: '/sale/api/order/query-order',
    method: 'post',
    data
  })
}
// 支付注册订单
export function payRegOrder(data) {
  return request({
    url: '/sale/api/order/pay-reg-order',
    method: 'post',
    data
  })
}
// 支付其他订单
export function payOthOrder(data) {
  return request({
    url: '/sale/api/order/pay-oth-order',
    method: 'post',
    data
  })
}
// 京东银行列表
export function jdBankList(params) {
  return request({
    url: '/pay/jd-fast/list',
    method: 'get',
    params
  })
}
// 通联银行列表
export function tlBankList(params) {
  return request({
    url: '/pay/all-in-fast/list',
    method: 'get',
    params
  })
}
// 汇付银行列表
export function hfBankList(params) {
  return request({
    url: '/pay/ada-fast/list',
    method: 'get',
    params
  })
}
// 京东绑卡
export function bindJd(data) {
  return request({
    url: '/pay/jd-fast/bind',
    method: 'post',
    data
  })
}
// 通联绑卡
export function bindTl(data) {
  return request({
    url: '/pay/all-in-fast/bind',
    method: 'post',
    data
  })
}
// 京东绑卡
export function bindJdConfirm(data) {
  return request({
    url: '/pay/jd-fast/bind-confirm',
    method: 'post',
    data
  })
}
// 通联绑卡
export function bindTlConfirm(data) {
  return request({
    url: '/pay/all-in-fast/bind-confirm',
    method: 'post',
    data
  })
}

//在线预充值
export function preCharge(params) {
  return request({
    url: '/member/api/recharge/preCharge',
    method: 'get',
    params
  })
}
//支付配置
export function payConfig(params) {
  return request({
    url: '/pay/config',
    method: 'get',
    params
  })
}

// 汇付绑卡
export function bindHf(data) {
  return request({
    url: '/pay/ada-fast/bind',
    method: 'post',
    data
  })
}
// 汇付绑卡确认
export function bindHfConfirm(data) {
  return request({
    url: '/pay/ada-fast/bind-confirm',
    method: 'post',
    data
  })
}
// 银行卡支付获取验证码
export function unifiedorder(data) {
  return request({
    url: '/pay/unifiedorder',
    method: 'post',
    data
  })
}
// 汇付银行卡确认
export function payConfirmHf(data) {
  return request({
    url: '/pay/ada-fast/pay-confirm',
    method: 'post',
    data
  })
}
// 通联银行卡确认
export function payConfirmTl(data) {
  return request({
    url: '/pay/all-in-fast/pay-confirm',
    method: 'post',
    data
  })
}
// 京东银行卡确认
export function payConfirmJd(data) {
  return request({
    url: '/pay/jd-fast/pay-confirm',
    method: 'post',
    data
  })
}
// 宝付确认支付h5
export function baoFuPay(data) {
  return request({
    url: '/pay/baofu-code/order',
    method: 'post',
    data
  })
}
// 汇付确认支付h5
export function huiFuPay(data) {
  return request({
    url: '/pay/ada-code/order',
    method: 'post',
    data
  })
}

// 宝付微信支付
export function payScanBf(data) {
  return request({
    url: '/baoFu/pay/createCode',
    method: 'post',
    data
  })
}

// 汇付微信支付
export function payScanHf(data) {
  return request({
    url: '/ada/pay/createCode',
    method: 'post',
    data
  })
}

// 支付状态
export function payStatus(params) {
  return request({
    url: '/pay/status',
    method: 'get',
    params
  })
}

//抽奖下单
export function payDrawGift(data) {
  return request({
    url: '/activity/api/draw/pay-draw-num',
    method: 'post',
    data
  })
}
//植树活动
export function shoppingTree(data){
  return request({
    url: 'activity/api/sa-tree-order/shoppingTree',
    method: 'post',
    data
  })
}
//植树查看账户
export function deductionAccount(data){
  return request({
    url: 'activity/api/sa-tree-order/deductionAccount',
    method: 'post',
    data
  })
}
//解绑银行卡
export function unBind(data){
  return request({
    url: '/pay/ada-fast/un-bind',
    method: 'post',
    data
  })
}
// 查询注册信息
export function registerInfo(params) {
  return request({
    url: '/member/api/member/register-order/' + params,
    method: 'get',
  })
}